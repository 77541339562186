
.Button{
  display: inline-block;
  padding: 10px 10px;
  margin-bottom: 0px;
  box-sizing: border-box;
  font-size: 0.9rem;
  /* width: 33%; */
  margin-right: 2%;
  border: none;
  border-radius: 2rem;
  font-family: inherit;
  background-color: #454a10;
  color: #f7ffd8;
  text-transform: capitalize;
  font-weight: 600;
  height: -moz-fit-content;
  height: fit-content;
  align-self: center;
  margin:auto;
  margin-bottom:18px;
}