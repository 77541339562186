.StatementsContainer {
  position: absolute;
  /* height: 100%; */
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
  /* the max height must be not set, the 148px for the actionbar+header 
   * are already subtracted from the grid-cell height this div is in 
   */
  /* max-height: calc(100% - 148px); */
  margin-left: 0px;
}

.StatementPage {
  position: absolute;
  top: 0;
  /* max-width: 480px; */
  box-sizing: border-box;
  /* left: calc(0px - var(--app-horizontal-padding));
  right: calc(0px - var(--app-horizontal-padding)); */
  left: 0;
  right: 0;
  padding: 0 var(--app-horizontal-padding);
  height: 100%;
  display: grid;
  flex-wrap: nowrap;
  flex-flow: column;
  align-content: flex-start;
  _overflow: auto;
  overflow-x: hidden;
  overflow-y: scroll;
}
.Statement {
  /* position: absolute; */
  /* width: 100%; */
  font-size: 1.5rem;
  /* _line-height:calc(100vw / 25px *1); */
  font-weight: bold;
  transition: font-size 0.3s ease;
  /* margin: auto; */
  z-index: 2;
  font-weight: 900;
  color: #f7ffd8;
  grid-row: 1/2;
  grid-column: 1/2;

  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}
.LongText {
  font-size: 1.3rem;
}
.Statement_blur {
  position: relative;
  width: 100%;
  font-size: 1.5rem;
  font-weight: bold;
  transition: font-size 0.3s ease;
  margin: auto;
  z-index: 2;
  font-weight: 900;
  color: #dcee3f;
  word-wrap: break-word;
  overflow-wrap: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
  grid-row: 1/2;
  grid-column: 1/2;
  align-self: center;
  filter: blur(5px);
  transform: scale(0.9);
  opacity: 0.5;
  margin-top: 40px;
  margin-right: -20px;
  z-index: -1;
  line-height: 1;
}
.StatementSource {
  position: relative;
  display: block;
  text-align: left;
  font-size: 1.32rem;
  /* opacity: 0.75; */
  color: #f7ffd8;
  font-weight: 600;
}
.StatementSource a {
  position: relative;
  display: block;
  text-align: left;
  margin-top: 0;
  padding-bottom: 20px;
  color: #f7ffd8;
  text-decoration: underline;

  padding-top: 20px;
}

.Statement::before {
  _content: open-quote;
  position: fixed;
  display: block;
  font-size: 8rem;
  margin-left: 0px;
  font-weight: 500;
  opacity: 0.5;
  top: 100px;
  left: 20px;
}
.Statement::after {
  content: close-quote;
  visibility: hidden;
}
/* This was targetting all spans, but has many side effects */
.StatementPage > span {
  position: relative;
  display: block;
  margin: auto;
  text-align: left;
  width: 100%;
  margin-top: 0;
}

.StatementPage > span.StatementSource {
  margin-top:1.24rem;
  z-index: 2;
}

.ShowComments .Statement {
  font-size: 1.5rem;
  margin: 0;
}
.ShowComments .StatementSource {
  display: none;
}
.ShowComments .StatementIcons {
  _display: none;
  top: 40px;
}
.ShowComments .Statement_blur {
  display: none;
}

.NextStatement {
  position: relative;
  display: none;
  opacity: 0;
  left: 0;
  right: 0;
  top: 0;
  font-size: 2.4rem;
  font-weight: bold;
  transition: all 0.3s ease;
  margin: auto;
  z-index: 0;
  grid-row: 1/2;
  grid-column: 1/2;
  align-self: center;
}

.StatementIcons {
  position: absolute;
  display: grid;
  top: 40px;
  right: calc(-20px + var(--app-horizontal-padding));
  width: auto;
  max-width: 480px;
  overflow: hidden;
  z-index: 100;
  grid-template-columns: repeat(2, 60px);
}
.ShowComments .StatementIcons svg#Layer_1 {
  position: relative;
  width: 25px;
  grid-column: 2/3;
  grid-row: 1/2;
  margin-top: 12px;
  justify-self: right;
}
.StatementShare {
  position: relative;
  display: block;
  grid-column: 1/2;
  grid-row: 1/2;
  height: 40px;
  width: 30px;
  justify-self: left;
  align-self: center;
  margin-top: 10px;
  margin-right: 0px;
}
.ShowComments .StatementShare {
  grid-column: 1/2;
  justify-self: start;
  margin-left: 0px;
}
.CloseCommentsButton {
  grid-column: 2/3;
  height: 30px;
  margin-top: 10px;
  margin-right: 30px;
  justify-self: right;
  fill: #f7ffd8;
}
.CloseCommentsButton path {
  fill: #f7ffd8;
}
.ShowComments {
  align-content: flex-start;
  overflow-y: scroll;
}

.ShowComments .Statement {
  position: relative;
  display: block;
  margin-top: 0px;
  margin-bottom: 20px;
  font-size: 1.2rem;
  font-weight: 900;
  color: #f7ffd8;
  align-self: flex-start;
}
.ShowComments .Statement::before {
  position: relative;
  font-size: 4rem;
  top: 0px;
  margin-top: -15px;
  margin-bottom: -20px;
  left: 0;
  display: block;
}
.SwipeLine {
  position: fixed;
  top: 0;
  bottom: 0;
  left: -5px;
  width: 10px;
  background-color: rgba(0, 0, 0, 0);
  backdrop-filter: blur(4px);
  z-index: 42042;
  pointer-events: none;
}
.QuotesIcon {
  width: 40px;
  opacity: 0.8 !important;
  margin-bottom: 8%;
  margin-top: 10%;
}
.ShowComments .QuotesIcon {
  margin-bottom: 20px;
  width: 24px;
}


.DeletedStatement{
  text-decoration: line-through;
}