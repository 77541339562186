.Buttons {
  position: absolute;
  top: 12px;
  left: 60px;
  height: 30px;
  display: flex;
  z-index: 30;
}
.CommentsButton,
.ShareButton {
  margin-right: 1rem;
}
.CommentsButton span {
  font-size: 0.7rem;
}

.CommentsButton svg,
.ShareButton svg {
  height: 28px;
   fill: #f7ffd8;
   
}
.RemoveButton {
  height: 24px;
  width:24px;
  margin-left:0.5rem;
}
.RemoveButton path {
  /* fill:rgba(136,143,46,0.5); */
}


.StatementRow{
  padding-left:20px;
  margin-left:-20px;
  margin-right:-20px;
  padding-bottom:12px;
  padding-right:32px;
  margin-bottom:6px;
}
.StatementRow {
  position: relative;
  display: grid;
  width: 100%;
  padding: 20px 25px;
  padding-right: 5px;
  padding-top: 10px;
  box-sizing: border-box;
  margin-bottom: 5px;
  grid-template-columns: 1fr 40px;
  grid-column-gap: 20px;
}

.StatementText {
  position: relative;
  display: block;
  grid-column: 1/2;
  grid-row: 1/2;
  align-self: center;
  margin-top: 0;
  margin-bottom: 10px;
  /* margin-left: 10px; */
}


.StatementText > span{
  display:inline;
  background:rgba(121, 127, 43, 0.5);
  box-shadow: 15px 0 0 rgb(121 127 43 / 50%), -80px 0 0 rgb(121 127 43 / 50%);
  font-size: 1.2rem;
  font-weight: 800;
  color: #f7ffd8;
  text-decoration: none;
}
.StatementText::before {
  content: open-quote;
  position: relative;
  display: block;
  font-weight: 900;
  font-size: 2rem;
  margin-bottom: -5px;
  color:#f7ffd8;
}
.StatementText::after {
  content: close-quote;
  display: block;
  font-size: 0;
}
.StatementRow button {
  position: relative;
  display: none;
  width: 100%;
  grid-column: 2/3;
  grid-row: span 1;
  margin: 0;
  align-self: center;
}
.StatementRow span.ManifestoDrag {
  position: relative;
  display: block;
  width: 32px;
  height: 40px;
  overflow: hidden;
  grid-column: 2/3;
  grid-row: 1/2;
  margin: 0;
  opacity: 0.66;
  align-self: flex-start;
}
.StatementRow span svg {
  fill: #f7ffd8;
  height: 100%;
}

.StatementText > span.StatementDeleted {
  text-decoration: line-through;
}