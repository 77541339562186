.CommentAdd {
  position: relative;
  display: block;
  box-sizing: border-box;
  width:100%;
  max-width: 480px;
  overflow: hidden;
  
  margin-bottom: 20px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.CommentAdd label {
  position: relative;
  display: block;
  color: #555;
  font-size: 0.8rem;
  margin-bottom: 10px;
}
.CommentAdd textarea {
  position: relative;
  display: block;
  width: 100%;
  margin: auto;
  padding: 6px 10px;
  margin-bottom: 10px;
  box-sizing: border-box;
  font-size: 0.9rem;
  font-family: inherit;
  border: none;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  height: 100px;
  background-color: rgba(255, 255, 255, 0.8);
  height: 30px;
  transition: all 0.2s linear;
}
.CommentAdd button {
  display: inline-block;
  padding: 10px 10px;
  margin-bottom: 0px;
  box-sizing: border-box;
  font-size: 0.9rem;
  /* width: 33%; */
  margin-right: 2%;
  border: none;
  border-radius: 2rem;
  font-family: inherit;
  background-color: #454a10;
  color: #f7ffd8;
  text-transform: capitalize;
  font-weight: 600;
  height: -moz-fit-content;
  height: fit-content;
  align-self: center;
}
.InFocus textarea {
  padding: 0.5rem;
  height: 4.5rem;
}
.InFocus button {
  display: inline-block;
}
