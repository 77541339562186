.CommentsListing {
  position: relative;
  display: block;
  width: 100%;

  box-sizing: border-box;
  max-width: 480px;
}

.Comment {
  position: relative;
  display: block;
  /* width: 100%; */
  margin-left: calc(0px - var(--app-horizontal-padding));
  margin-right: calc(0px - var(--app-horizontal-padding));
  padding: 15px calc(5px + var(--app-horizontal-padding));
  background-color: rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  margin-bottom: 5px;

  overflow: hidden;
}

.Comment p {
  font-size: 0.9rem;
  margin-top: 0;
  margin-bottom: 5px;
  color: #333;
  font-weight: 400;
}

.MetaData {
  display: grid;
  grid-template-columns: 0px 1fr auto;
  align-content: center;
  justify-items: left;
}
.MetaData time {
  font-size: 0.8rem;
  grid-row: 1/2;
  grid-column: 3/4;
  align-self: center;
  font-family: inherit;
  margin-top: 12px;
  font-style: italic;
  font-weight: 600;
  opacity: 0.5;
}
.MetaData a {
  grid-row: 1/2;
  grid-column: 1/2;
  align-self: center;
  text-decoration: none;
  color: #333;
  font-weight: 900;
  font-size: 0.8rem;
  margin-top: 10px;
}
.MetaData a:first-of-type {
  grid-row: 1/2;
  grid-column: 2/3;
  padding-left: 0px;
  align-self: center;
}
.MetaData img {
  max-height: 30px;
  display: none;
}
.LoginToComment{
  display: inline-block;
  padding: 10px 10px;
  margin-bottom: 0px;
  box-sizing: border-box;
  font-size: 0.9rem;
  /* width: 33%; */
  margin-right: 2%;
  border: none;
  border-radius: 2rem;
  font-family: inherit;
  background-color: #454a10;
  color: #f7ffd8;
  text-transform: capitalize;
  font-weight: 600;
  height: -moz-fit-content;
  height: fit-content;
  align-self: center;
}

.Avatar{
  position: relative;
  height: 32px;
  display: block;
  width: 32px;
  background-color: #c8d72c;
  background-size: contain;
  mask-mode: alpha;
  mask-image: url(../../assets/avatar/avatar-mask-1024-black.png);
  mask-size: 32px;
  mask-repeat: no-repeat;
  mask-position: center;
  float:left;
  margin-top:-6px;
}

.Moderation{
  text-align:right;
  padding-top:5px;
}

.Moderation button{
  display: inline-block;
  padding: 2px 10px 5px 10px;
  margin-bottom: 0px;
  box-sizing: border-box;
  font-size: 0.9rem;
  /* width: 33%; */
  border: none;
  border-radius: 2rem;
  font-family: inherit;
  background-color: #454a10;
  color: #f7ffd8;
  font-weight: 600;
  height: -moz-fit-content;
  height: fit-content;
  align-self: center;
}
.DeleteIcon{
  font-size:4px;
  display:inline-block;
  margin-top:-2px;
  margin-right:8px;
  height:2px;
}
.ApproveIcon{
  font-size:10px;
  display:inline-block;
  margin-top:-2px;
  margin-right:8px;
  height:2px;
}
.ButtonText{
  font-size:12px;
}