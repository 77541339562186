.AvatarPicker {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1em;
}

.AvatarPicker span{
  transition: all .2s ease-in-out;
  border-radius:50%;
}

.Current {
  background-color: rgba(247, 255, 214, .4);
}
