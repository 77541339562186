.CreatePage {
  position: relative;
  display: grid;
  height: 100%;
  grid-template-rows: .25fr 2fr;
}
.LoginForm input {
  display: block;
  width: 100%;
  margin: auto;
  padding: 15px 10px;
  margin-bottom: 20px;
  box-sizing: border-box;
  font-size: 1rem;
  font-family: inherit;
  border: thin #999 solid;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background-color: #f7ffd8;
}
.EmailInput {
}
.PasswordInput {
}

.CreatePage button {
  display: inline-block;
  padding: 10px 10px;
  margin-bottom: 0px;
  box-sizing: border-box;
  font-size: 0.9rem;
  /* width: 33%; */
  margin-right: 2%;
  border: none;
  border-radius: 2rem;
  font-family: inherit;
  background-color: #454a10;
  color: #f7ffd8;
  text-transform: capitalize;
  font-weight: 600;
  height: -moz-fit-content;
  height: fit-content;
  align-self: center;
}
.LoginLink {   
  display:inline-block; 
  width: 45%;
  text-align: center;
}