.ContentSection {
  position: relative;
  display: block;
  box-sizing: border-box;
  margin-left: -25px;
  margin-right: -20px;
  padding: 25px;
  /* background-color: rgba(255, 255, 255, 0.5); */
  margin-bottom: 20px;
  height: fit-content;
}
