.Buttons {
  position: absolute;
  top: 12px;
  left: 60px;
  height: 30px;
  display: flex;
  z-index: 30;
}
.CommentsButton,
.ShareButton {
  margin-right: 1rem;
}
.CommentsButton span {
  font-size: 0.7rem;
}

.CommentsButton svg,
.ShareButton svg {
  height: 28px;
   fill: #f7ffd8;
   
}
.RemoveButton {
  height: 24px;
  width:24px;
  margin-left:0.5rem;
}
.RemoveButton path {
  /* fill:rgba(136,143,46,0.5); */
  fill: #f7ffd8;
}
