.LogoutPage {
  position: relative;
  display: grid;
  height: 100%;
  grid-template-rows: 1fr 2fr;
}
.LogoutPage h2{
	display: none;
}
.LogoutPage button {
  display: inline-block;
  padding: 10px 10px;
  margin-bottom: 00px;
  box-sizing: border-box;
  font-size: 0.9rem;
  /* width: 33%; */
  margin-right: 2%;
  border: none;
  border-radius: 2rem;
  font-family: inherit;
  background-color: #454a10;;
  color:#f7ffd8;;
  text-transform: capitalize;
  font-weight: 600;
	height: fit-content;
	align-self: center;
}