.LoginPage {
  position: relative;
  display: block;
  height: 100%;
  grid-template-rows: 1fr 2fr;
}

.LoginPage section {
  /* JM moved to ../App/ContentSection.module.css */
  /* still possible to add page specific styling 
  * by adding a class here and assigninig it to the ContentSection className
  */
}

.LoginForm {
  position: relative;
  display: block;
  align-self: flex-start;
}
.SignupLink {
}

.LoginForm input {
  display: block;
  width: 100%;
  margin: auto;
  padding: 15px 10px;
  margin-bottom: 30px;
  box-sizing: border-box;
  font-size: 1rem;
  font-family: inherit;
  border: none;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  background-color: #f7ffd8;
}
.EmailInput {
}
.PasswordInput {
}

.LoginPage button {
  display: inline-block;
  padding: 10px 10px;
  margin-bottom: 20px;
  box-sizing: border-box;
  font-size: 0.9rem;
  font-weight: 600;
  width: 49%;
  margin-right: 2%;
  border: none;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 2rem;
  font-family: inherit;
  background-color: #454a10;;
  color: #f7ffd8;
}
.LoginPage a {
  display: block;
  text-decoration: none;
  color: #f7ffd8;
  margin-top: 0px;
  font-size: 0.9rem;
}
.LoginPage a.SignupLink {
  display: inline-block;
  padding: 10px 10px;
  margin-top: 0;
  margin-bottom: 20px;
  box-sizing: border-box;
  font-size: 0.9rem;
  font-weight: 600;
  width: 49%;
  text-align: center;
  border: none;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  text-decoration: none;
  color: #f7ffd8;
}
