.App {
  position: fixed;

  /* display: grid; */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  /* grid-template-columns: 20px auto 20px;
  grid-template-rows: 80px auto max-content; */
  /* width: 100%;
  height: 100%; */
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  text-align:center;
  --header-x-pad: env(safe-area-inset-top);
  --header-height: calc(80px + var(--header-x-pad, 0));

  /* margin: auto; */
  /* background-color: #9baa0f; */
  /* _background-image: url(../../assets/scanlines.png); */
}
.App > * {
  text-align:left;
}
@media screen and (min-width: 480px) {
  .App {
    position: absolute;
  }
}
.Backdrop {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: url(../../assets/backgrounds/caim_skins_14.png);
  background-size: cover;
  background-position: center center;
}

.Header {
  position: relative;
  display: block;
  min-height: 80px;
  min-height: var(--header-height);
  /* grid-row: 1/2; */
  /* grid-column: 1/4; */
  /* _background-image: url(../../assets/scanlines.png); */
}

.Menu {
  position: absolute;
  display: block;
  /* grid-row: 2/3;
  grid-column: 1/3; */
   width: 100%; 
 /* width: 60%;
  max-width:288px;*/
  max-width:480px;
  z-index: 10000;
  top: 0;
  /* top: var(--header-height); */
  bottom: 0;
}

.Container {
  position: relative;
  display: block;
  flex-grow: 2;
  /* height: 100%; */
  /* grid-row: 2/3; */
  /* grid-column: 1/4; */
  overflow: hidden;
  overflow-y: auto;
  /* align-self: center; */
  --app-horizontal-padding: 27px;
  padding-left: var(--app-horizontal-padding);
  padding-right: var(--app-horizontal-padding);

  user-select: text;
  /* width:100%; */
  /* For desktop view */
  /* margin: auto; */
  text-align: left;
}

@media screen and (min-width: 481px) {
  .Container {
    max-width: 480px;
    max-height: 960px;
  }
}
.Container > * {
  /*width:calc(100% - 40px);*/
}

.ActionBar {
  position: relative;
  /* display: grid; */
  /* grid-column: 1/4; */
  /* grid-row: 3/4; */
  z-index: 1000;
  width: 100%;
  /* max-width: 480px; */
  /* box-sizing: border-box; */
  /* align-content: flex-end; */
  /* _background-image: url(../../assets/scanlines.png); */
}
