.PopUp{
  position:absolute;
  width: 100%;
  height:100%;
  z-index:12000;
}

.PopUpBackground{
  background:rgba(154, 164, 43, .6)  url("../../assets/popup_background-01.svg") no-repeat;
  /* background:; */
  width: 100%;
  height:100%;
  position:absolute; 
  top:0;
  left:0;
}

.PopUpContent{
  width: calc(100% - 180px);
  margin:70px;
  height:auto;
  padding:20px;
  position:absolute; 
  top:0;
  left:0;
  min-height:50%;
}