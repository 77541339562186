.AboutPage {
  position: relative;
  /* display: flex; */
  /* _width: 100%; */
  height: auto;
  margin-top:120px;
  padding: 20px;
  padding-left:38px;
  margin-left: -30px;
  margin-right: -30px;
  background-color: rgba(121,127,43,.5);
  flex-wrap: wrap;
  flex-flow: column;
  box-sizing: border-box;
  align-content: center;
  align-items: center;

  _box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.5);
}
.AboutPage h3{
  color:#F7FFD8;
}

.AboutPageHeader {
  color: #F7FFD8;
  margin-bottom: 20px !important;
  align-self: center;
  margin-top: auto;
}
.AboutPageSubHeader {
  color: #333#F7FFD8;
}
p {
  margin-bottom: 20px;
  align-self: center;
  color: #F7FFD8;
}
p:last-child {
  margin-bottom: auto;
}
