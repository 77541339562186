.Menu {
  transform: translateX(-100%);
  transition: transform 0.2s ease-in-out;
  background-color: #9baa0f;
}
.Menu.Show {
  transform: translateX(0);
}
.Backdrop{
  opacity: 1;
}
.Menu ul {
  position: relative;
  height:100%;
  /* display: grid;
  grid-template-columns: 20px auto 20px; */
  list-style: none;
  /* height: auto; */
  margin: 0;
  padding: 0;
  padding-top: 10%;
  padding-bottom: 15%;
  box-sizing: border-box;
}

.Menu ul li {
  /* position: relative;
  display: flex;
  grid-column: 2/3;
  grid-row: span 1;
  align-content: center; */
}
.Menu ul li.AccountMenuItem {
  display: grid;
  grid-template-columns: auto 1fr;
}

li.AccountMenuItem a img {
  margin-right: 20px;
}
.Menu ul li a {
  position: absolute;
  display: block;
  text-decoration: none;
  align-self: center;
  color: #eee;
  font-size: 1.5rem;
  text-transform: lowercase;
  font-variant: all-small-caps;
  letter-spacing: 2px;
  padding-top: 0px;
  padding-bottom: 10px;
}
.Menu ul li a img{
  width:100%;
  height:auto;
}

.AccountMenuItem a {
  height: 30px;
  position: relative;
  display: block;
}
.AccountMenuItem a img {
  /* display: inline-block; */
}

.ButtonMask{
  background-size:90%;
  mask-mode:alpha;
  mask-image: url(../../assets/backgrounds/caim-menu-background.png);
  mask-size: 150%;
  mask-repeat: no-repeat;
  mask-position: 50% 30%;
  background: rgba(255, 255, 255, 0.6);
  padding:10%;
}
.AboutButton{
  mask-size: 230%;
  mask-position: -3% 13%;
}
.CommonManifestoButton{
  mask-size: 137%;
  mask-position: 114% 11%;
}
.BrowseStatementsButton{
  mask-position: -6% 41%;
}
.AccountButton{
  mask-size: 232%;
  mask-position: 99% 40%;
}
.ContributeStatementButton{
  mask-size: 150%;
  mask-position: 109% 60%;
}
.MyManifestoButton{
  mask-position: -6% 79%;
}
.ModerateContentButton{
  mask-position: 112% 97%;
}
/* 
.ButtonMask{
  animation: glitch 1s linear infinite;
}

@keyframes glitch{
  2%,64%{
    transform: translate(2px,0) skew(0deg);
  }
  4%,60%{
    transform: translate(-2px,0) skew(0deg);
  }
  62%{
    transform: translate(0,0) skew(5deg); 
  }
}

.ButtonMask:before,
.ButtonMask:after{
  content: attr(title);
  position: absolute;
  left: 0;
}

.ButtonMask:before{
  animation: glitchTop 1s linear infinite;
  clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
}

@keyframes glitchTop{
  2%,64%{
    transform: translate(2px,-2px);
  }
  4%,60%{
    transform: translate(-2px,2px);
  }
  62%{
    transform: translate(13px,-1px) skew(-13deg); 
  }
}

.ButtonMask:after{
  animation: glitchBotom 1.5s linear infinite;
  clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
  -webkit-clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
}

@keyframes glitchBotom{
  2%,64%{
    transform: translate(-2px,0);
  }
  4%,60%{
    transform: translate(-2px,0);
  }
  62%{
    transform: translate(-22px,5px) skew(21deg); 
  }
} */