.ManifestoPage {
  margin-left: -20px;
  margin-right: -20px;
}
.PageHeader {
  /* padding-left: 10px; */
}
.Avatar {
  max-width: 40px;
  display: inline-block;
  margin-right: 10px;
}
.ShareButton {
  width: 30px;
}
.ShareButton svg {
  position: absolute;
  display: block;
  width: 30px;
  height: auto;
  top: 25px;
  right: 15px;
  opacity: 0.8;
}
.ManifestoPage ul {
  position: relative;
  display: block;
  list-style: none;
  padding: 0;
}

.AverageManifestoItem{
  padding-left:20px;
  margin-left:-20px;
  margin-right:-20px;
  padding-bottom:12px;
  padding-right:32px;
  margin-bottom:6px;
}
.ManifestoItem {
  position: relative;
  display: grid;
  width: 100%;
  padding: 20px 25px;
  padding-right: 5px;
  padding-top: 10px;
  box-sizing: border-box;
  margin-bottom: 5px;
  grid-template-columns: 1fr 40px;
  grid-column-gap: 20px;
}

.StatementText {
  position: relative;
  display: block;
  grid-column: 1/2;
  grid-row: 1/2;
  align-self: center;
  margin-top: 0;
  margin-bottom: 10px;
  /* margin-left: 10px; */
}

/*
.StatementText > span{
  display:inline;
  background:rgba(121, 127, 43, 0.5);
  box-shadow: 15px 0 0 rgb(121 127 43 / 50%), -20px 0 0 rgb(121 127 43 / 50%);
  font-size: 1.2rem;
  font-weight: 800;
  color: #f7ffd8;
  text-decoration: none;
}
.StatementText::before {
  content: open-quote;
  position: relative;
  display: block;
  font-weight: 900;
  font-size: 2rem;
  margin-bottom: -5px;
  color:#f7ffd8;
}
.StatementText::after {
  content: close-quote;
  display: block;
  font-size: 0;
}*/
.ManifestoItem button {
  position: relative;
  display: none;
  width: 100%;
  grid-column: 2/3;
  grid-row: span 1;
  margin: 0;
  align-self: center;
}
.ManifestoItem span.ManifestoDrag {
  position: relative;
  display: block;
  width: 32px;
  height: 40px;
  overflow: hidden;
  grid-column: 2/3;
  grid-row: 1/2;
  margin: 0;
  opacity: 0.66;
  align-self: flex-start;
}
.ManifestoItem span svg {
  fill: #f7ffd8;
  height: 100%;
}
.PublicCountSeparator {
  position: relative;
  display: grid;
  font-size: 0.8rem;
  grid-template-columns: 9fr 1fr;
  grid-template-rows: 1fr auto 1fr;
  margin-left: 15px;
  margin-bottom: 5px;
  opacity: 0.8;
}
.PublicCountSeparator span {
  position: relative;
  display: block;
  grid-column: 2/3;
  grid-row: 1/4;
  align-self: center;
  justify-self: right;
  right: 0px;
}
.PublicCountSeparator span svg {
  fill: #f7ffd8;
  height: 40px;
}
.PublicCountSeparator hr {
  position: relative;
  display: block;
  grid-column: 1/2;
  margin: 0;
  grid-row: 2/3;
}
.PublicCountSeparator label {
  position: relative;
  display: block;
  grid-column: 1/2;
  grid-row: span 1;
  align-self: flex-start;
  font-size: 0.8rem;
  color: #f7ffd8;
  text-decoration: none;
  margin-top: 5px;
  padding-left: 5px;
}

.PublicCountSeparator label:first-of-type {
  align-self: flex-end;
  margin-bottom: 5px;
}

.PublicCountSeparator button {
  position: relative;
  display: none;
  width: 100%;
  grid-column: 2/3;
  grid-row: span 1;
  margin: 0;
  align-self: center;
  right: 4px;
}
