@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,300;0,600;0,900;1,300;1,600;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap);
html {
  /* https://allthingssmitty.com/2020/05/11/css-fix-for-100vh-in-mobile-webkit/ */
  height: -webkit-fill-available;
  scroll-boundary-behavior: none;
}
body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
body {
  margin: 0;
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(154, 164, 43);
  -webkit-user-select: none; /* Chrome all / Safari all */ /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none;
}
@media screen and (min-width: 480px) {
  body {
    background-color: black;
  }
}
#root {
  position: relative;
  flex-grow: 2;
  display: block;
  max-width: 480px;
  max-height: 960px;
  overflow: hidden;
  height: 100%;
  width: 100%;
  margin: auto;
  background-color: rgb(154, 164, 43);
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  display: inline-block;
  padding: 10px 10px;
  margin-bottom: 20px;
  box-sizing: border-box;
  font-size: 1rem;
  width: 49%;
  margin-right: 2%;
  border: thin #999 solid;
  border-radius: 3px;
  font-family: inherit;
}
a {
  display: block;
  text-decoration: none;
  color: #f7ffd8;
  margin-top: 20px;
}

.moderation div#root{
  max-width:100%;
  max-height:100%;
}

.moderation div#root div div:nth-child(4){
  max-width:100%;
  max-height:100%;
}
.moderation > div#root > div > div:nth-child(1) > svg{
  display:none;
}
.moderation > div#root > div > div:first-child{
  background:no-repeat;
  background-position: 6000px 6000px;
}
.App_App__aOmNj {
  position: fixed;

  /* display: grid; */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  /* grid-template-columns: 20px auto 20px;
  grid-template-rows: 80px auto max-content; */
  /* width: 100%;
  height: 100%; */
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  text-align:center;
  --header-x-pad: env(safe-area-inset-top);
  --header-height: calc(80px + var(--header-x-pad, 0));

  /* margin: auto; */
  /* background-color: #9baa0f; */
  /* _background-image: url(../../assets/scanlines.png); */
}
.App_App__aOmNj > * {
  text-align:left;
}
@media screen and (min-width: 480px) {
  .App_App__aOmNj {
    position: absolute;
  }
}
.App_Backdrop__1IxWs {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: url(/static/media/caim_skins_14.a6fbefab.png);
  background-size: cover;
  background-position: center center;
}

.App_Header__2rl_K {
  position: relative;
  display: block;
  min-height: 80px;
  min-height: var(--header-height);
  /* grid-row: 1/2; */
  /* grid-column: 1/4; */
  /* _background-image: url(../../assets/scanlines.png); */
}

.App_Menu__1pLb5 {
  position: absolute;
  display: block;
  /* grid-row: 2/3;
  grid-column: 1/3; */
   width: 100%; 
 /* width: 60%;
  max-width:288px;*/
  max-width:480px;
  z-index: 10000;
  top: 0;
  /* top: var(--header-height); */
  bottom: 0;
}

.App_Container__1KI49 {
  position: relative;
  display: block;
  flex-grow: 2;
  /* height: 100%; */
  /* grid-row: 2/3; */
  /* grid-column: 1/4; */
  overflow: hidden;
  overflow-y: auto;
  /* align-self: center; */
  --app-horizontal-padding: 27px;
  padding-left: var(--app-horizontal-padding);
  padding-right: var(--app-horizontal-padding);

  -webkit-user-select: text;

      -ms-user-select: text;

          user-select: text;
  /* width:100%; */
  /* For desktop view */
  /* margin: auto; */
  text-align: left;
}

@media screen and (min-width: 481px) {
  .App_Container__1KI49 {
    max-width: 480px;
    max-height: 960px;
  }
}
.App_Container__1KI49 > * {
  /*width:calc(100% - 40px);*/
}

.App_ActionBar__2qq1b {
  position: relative;
  /* display: grid; */
  /* grid-column: 1/4; */
  /* grid-row: 3/4; */
  z-index: 1000;
  width: 100%;
  /* max-width: 480px; */
  /* box-sizing: border-box; */
  /* align-content: flex-end; */
  /* _background-image: url(../../assets/scanlines.png); */
}



.StatementPage_StatementsContainer__PHsDY {
  position: absolute;
  /* height: 100%; */
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
  /* the max height must be not set, the 148px for the actionbar+header 
   * are already subtracted from the grid-cell height this div is in 
   */
  /* max-height: calc(100% - 148px); */
  margin-left: 0px;
}

.StatementPage_StatementPage__38uXn {
  position: absolute;
  top: 0;
  /* max-width: 480px; */
  box-sizing: border-box;
  /* left: calc(0px - var(--app-horizontal-padding));
  right: calc(0px - var(--app-horizontal-padding)); */
  left: 0;
  right: 0;
  padding: 0 var(--app-horizontal-padding);
  height: 100%;
  display: grid;
  flex-wrap: nowrap;
  flex-flow: column;
  align-content: flex-start;
  _overflow: auto;
  overflow-x: hidden;
  overflow-y: scroll;
}
.StatementPage_Statement__27-r4 {
  /* position: absolute; */
  /* width: 100%; */
  font-size: 1.5rem;
  /* _line-height:calc(100vw / 25px *1); */
  font-weight: bold;
  transition: font-size 0.3s ease;
  /* margin: auto; */
  z-index: 2;
  font-weight: 900;
  color: #f7ffd8;
  grid-row: 1/2;
  grid-column: 1/2;

  -webkit-hyphens: auto;
  -ms-hyphens: auto;
      hyphens: auto;
}
.StatementPage_LongText__1-As0 {
  font-size: 1.3rem;
}
.StatementPage_Statement_blur__3c81z {
  position: relative;
  width: 100%;
  font-size: 1.5rem;
  font-weight: bold;
  transition: font-size 0.3s ease;
  margin: auto;
  z-index: 2;
  font-weight: 900;
  color: #dcee3f;
  word-wrap: break-word;
  overflow-wrap: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
      hyphens: auto;
  grid-row: 1/2;
  grid-column: 1/2;
  align-self: center;
  -webkit-filter: blur(5px);
          filter: blur(5px);
  transform: scale(0.9);
  opacity: 0.5;
  margin-top: 40px;
  margin-right: -20px;
  z-index: -1;
  line-height: 1;
}
.StatementPage_StatementSource__1q6lU {
  position: relative;
  display: block;
  text-align: left;
  font-size: 1.32rem;
  /* opacity: 0.75; */
  color: #f7ffd8;
  font-weight: 600;
}
.StatementPage_StatementSource__1q6lU a {
  position: relative;
  display: block;
  text-align: left;
  margin-top: 0;
  padding-bottom: 20px;
  color: #f7ffd8;
  text-decoration: underline;

  padding-top: 20px;
}

.StatementPage_Statement__27-r4::before {
  _content: open-quote;
  position: fixed;
  display: block;
  font-size: 8rem;
  margin-left: 0px;
  font-weight: 500;
  opacity: 0.5;
  top: 100px;
  left: 20px;
}
.StatementPage_Statement__27-r4::after {
  content: close-quote;
  visibility: hidden;
}
/* This was targetting all spans, but has many side effects */
.StatementPage_StatementPage__38uXn > span {
  position: relative;
  display: block;
  margin: auto;
  text-align: left;
  width: 100%;
  margin-top: 0;
}

.StatementPage_StatementPage__38uXn > span.StatementPage_StatementSource__1q6lU {
  margin-top:1.24rem;
  z-index: 2;
}

.StatementPage_ShowComments__3yL-y .StatementPage_Statement__27-r4 {
  font-size: 1.5rem;
  margin: 0;
}
.StatementPage_ShowComments__3yL-y .StatementPage_StatementSource__1q6lU {
  display: none;
}
.StatementPage_ShowComments__3yL-y .StatementPage_StatementIcons__3qa4q {
  _display: none;
  top: 40px;
}
.StatementPage_ShowComments__3yL-y .StatementPage_Statement_blur__3c81z {
  display: none;
}

.StatementPage_NextStatement__Bb6Kb {
  position: relative;
  display: none;
  opacity: 0;
  left: 0;
  right: 0;
  top: 0;
  font-size: 2.4rem;
  font-weight: bold;
  transition: all 0.3s ease;
  margin: auto;
  z-index: 0;
  grid-row: 1/2;
  grid-column: 1/2;
  align-self: center;
}

.StatementPage_StatementIcons__3qa4q {
  position: absolute;
  display: grid;
  top: 40px;
  right: calc(-20px + var(--app-horizontal-padding));
  width: auto;
  max-width: 480px;
  overflow: hidden;
  z-index: 100;
  grid-template-columns: repeat(2, 60px);
}
.StatementPage_ShowComments__3yL-y .StatementPage_StatementIcons__3qa4q svg#StatementPage_Layer_1__1XEWA {
  position: relative;
  width: 25px;
  grid-column: 2/3;
  grid-row: 1/2;
  margin-top: 12px;
  justify-self: right;
}
.StatementPage_StatementShare__gIvAF {
  position: relative;
  display: block;
  grid-column: 1/2;
  grid-row: 1/2;
  height: 40px;
  width: 30px;
  justify-self: left;
  align-self: center;
  margin-top: 10px;
  margin-right: 0px;
}
.StatementPage_ShowComments__3yL-y .StatementPage_StatementShare__gIvAF {
  grid-column: 1/2;
  justify-self: start;
  margin-left: 0px;
}
.StatementPage_CloseCommentsButton__n-svd {
  grid-column: 2/3;
  height: 30px;
  margin-top: 10px;
  margin-right: 30px;
  justify-self: right;
  fill: #f7ffd8;
}
.StatementPage_CloseCommentsButton__n-svd path {
  fill: #f7ffd8;
}
.StatementPage_ShowComments__3yL-y {
  align-content: flex-start;
  overflow-y: scroll;
}

.StatementPage_ShowComments__3yL-y .StatementPage_Statement__27-r4 {
  position: relative;
  display: block;
  margin-top: 0px;
  margin-bottom: 20px;
  font-size: 1.2rem;
  font-weight: 900;
  color: #f7ffd8;
  align-self: flex-start;
}
.StatementPage_ShowComments__3yL-y .StatementPage_Statement__27-r4::before {
  position: relative;
  font-size: 4rem;
  top: 0px;
  margin-top: -15px;
  margin-bottom: -20px;
  left: 0;
  display: block;
}
.StatementPage_SwipeLine__LkNDB {
  position: fixed;
  top: 0;
  bottom: 0;
  left: -5px;
  width: 10px;
  background-color: rgba(0, 0, 0, 0);
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  z-index: 42042;
  pointer-events: none;
}
.StatementPage_QuotesIcon__2muzx {
  width: 40px;
  opacity: 0.8 !important;
  margin-bottom: 8%;
  margin-top: 10%;
}
.StatementPage_ShowComments__3yL-y .StatementPage_QuotesIcon__2muzx {
  margin-bottom: 20px;
  width: 24px;
}


.StatementPage_DeletedStatement__3Wy4Q{
  text-decoration: line-through;
}
.CommentAdd_CommentAdd__2-69U {
  position: relative;
  display: block;
  box-sizing: border-box;
  width:100%;
  max-width: 480px;
  overflow: hidden;
  
  margin-bottom: 20px;
  border-radius: 5px;
}
.CommentAdd_CommentAdd__2-69U label {
  position: relative;
  display: block;
  color: #555;
  font-size: 0.8rem;
  margin-bottom: 10px;
}
.CommentAdd_CommentAdd__2-69U textarea {
  position: relative;
  display: block;
  width: 100%;
  margin: auto;
  padding: 6px 10px;
  margin-bottom: 10px;
  box-sizing: border-box;
  font-size: 0.9rem;
  font-family: inherit;
  border: none;
  border-radius: 3px;
  height: 100px;
  background-color: rgba(255, 255, 255, 0.8);
  height: 30px;
  transition: all 0.2s linear;
}
.CommentAdd_CommentAdd__2-69U button {
  display: inline-block;
  padding: 10px 10px;
  margin-bottom: 0px;
  box-sizing: border-box;
  font-size: 0.9rem;
  /* width: 33%; */
  margin-right: 2%;
  border: none;
  border-radius: 2rem;
  font-family: inherit;
  background-color: #454a10;
  color: #f7ffd8;
  text-transform: capitalize;
  font-weight: 600;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  align-self: center;
}
.CommentAdd_InFocus__2Fqvl textarea {
  padding: 0.5rem;
  height: 4.5rem;
}
.CommentAdd_InFocus__2Fqvl button {
  display: inline-block;
}

.CommentsListing_CommentsListing__1rhhk {
  position: relative;
  display: block;
  width: 100%;

  box-sizing: border-box;
  max-width: 480px;
}

.CommentsListing_Comment__wQi-S {
  position: relative;
  display: block;
  /* width: 100%; */
  margin-left: calc(0px - var(--app-horizontal-padding));
  margin-right: calc(0px - var(--app-horizontal-padding));
  padding: 15px calc(5px + var(--app-horizontal-padding));
  background-color: rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  margin-bottom: 5px;

  overflow: hidden;
}

.CommentsListing_Comment__wQi-S p {
  font-size: 0.9rem;
  margin-top: 0;
  margin-bottom: 5px;
  color: #333;
  font-weight: 400;
}

.CommentsListing_MetaData__2pipz {
  display: grid;
  grid-template-columns: 0px 1fr auto;
  align-content: center;
  justify-items: left;
}
.CommentsListing_MetaData__2pipz time {
  font-size: 0.8rem;
  grid-row: 1/2;
  grid-column: 3/4;
  align-self: center;
  font-family: inherit;
  margin-top: 12px;
  font-style: italic;
  font-weight: 600;
  opacity: 0.5;
}
.CommentsListing_MetaData__2pipz a {
  grid-row: 1/2;
  grid-column: 1/2;
  align-self: center;
  text-decoration: none;
  color: #333;
  font-weight: 900;
  font-size: 0.8rem;
  margin-top: 10px;
}
.CommentsListing_MetaData__2pipz a:first-of-type {
  grid-row: 1/2;
  grid-column: 2/3;
  padding-left: 0px;
  align-self: center;
}
.CommentsListing_MetaData__2pipz img {
  max-height: 30px;
  display: none;
}
.CommentsListing_LoginToComment__3IAiS{
  display: inline-block;
  padding: 10px 10px;
  margin-bottom: 0px;
  box-sizing: border-box;
  font-size: 0.9rem;
  /* width: 33%; */
  margin-right: 2%;
  border: none;
  border-radius: 2rem;
  font-family: inherit;
  background-color: #454a10;
  color: #f7ffd8;
  text-transform: capitalize;
  font-weight: 600;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  align-self: center;
}

.CommentsListing_Avatar__TYLaN{
  position: relative;
  height: 32px;
  display: block;
  width: 32px;
  background-color: #c8d72c;
  background-size: contain;
  mask-mode: alpha;
  -webkit-mask-image: url(/static/media/avatar-mask-1024-black.848d2a56.png);
          mask-image: url(/static/media/avatar-mask-1024-black.848d2a56.png);
  -webkit-mask-size: 32px;
          mask-size: 32px;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-position: center;
          mask-position: center;
  float:left;
  margin-top:-6px;
}

.CommentsListing_Moderation__V9irE{
  text-align:right;
  padding-top:5px;
}

.CommentsListing_Moderation__V9irE button{
  display: inline-block;
  padding: 2px 10px 5px 10px;
  margin-bottom: 0px;
  box-sizing: border-box;
  font-size: 0.9rem;
  /* width: 33%; */
  border: none;
  border-radius: 2rem;
  font-family: inherit;
  background-color: #454a10;
  color: #f7ffd8;
  font-weight: 600;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  align-self: center;
}
.CommentsListing_DeleteIcon__2VBS5{
  font-size:4px;
  display:inline-block;
  margin-top:-2px;
  margin-right:8px;
  height:2px;
}
.CommentsListing_ApproveIcon__f4C5y{
  font-size:10px;
  display:inline-block;
  margin-top:-2px;
  margin-right:8px;
  height:2px;
}
.CommentsListing_ButtonText__-FRn7{
  font-size:12px;
}
.Blur_Container__3_RKd {
  position: relative;
  display: block;
  width:100%;
}
.Blur_Original__25L_9 {
  position: relative;
  display: block;
  width: 100%;
    display: inline;
  background: rgba(121,127,43,0.5);
  box-shadow: 15px 0 0 rgba(121,127,43,0.5), -80px 0 0 rgba(121,127,43,0.5);
}

.Blur_Blur__25J0x {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  width: 100%;
  z-index: -1;
}

.ColorShadow_Container__3dYSS {
  position: relative;
  display: inline-block;
}
.ColorShadow_Shadow__1x33F {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -2;
}
.ColorShadow_Shadow__1x33F div {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url(/static/media/blob.6ec21081.png);
  background-size: 100%;
  background-position: center;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-size: 100%;
          mask-size: 100%;
}

.CommentsButton_CommentsButton__1NTy0 {
  display: grid;
  justify-content: left;
  
}

.CommentsButton_CommentsButton__1NTy0 label {
  display: none;
}
.CommentsButton_CommentsButton__1NTy0 svg {
  position: relative;
  display: block;
  width: 32px;

  grid-column: 1/2;
  grid-row: 1/2;
  justify-self: right;
  align-self: center;
  opacity: 1;
}
.CommentsButton_CommentsButton__1NTy0 span {
  position: relative;
  display: block;
  grid-column: 1/2;
  grid-row: 1/2;
  justify-self: center;
  align-self: center;
  text-align: center;
  z-index: 10;
  color: #748000;
  font-weight: 600;
  font-size: 0.8rem;
  _padding-right: 20%;
  font-weight: 900;
  _width:40px;
  /* margin-top: 15px; */
}
.CommentsButton_CommentsButton__1NTy0 svg path {
  fill: #f7ffd8;
}

.ShareButton_ShareButton__103wN svg {
  max-height: 100%;
  max-width: 100%;
  fill: #f7ffd8;
}

.SharePanel_SharePanel__1hqTY {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  align-content: center;
  width: 100vw;
  max-width: 480px;
  padding: 25px;
  box-sizing: border-box;
  border-radius: 3px;
  background-color: rgba(127,135,37,0.8);
  z-index: 10000;
}
.SharePanel_CloseButton__30z2O {
  grid-column: 1/4;
  grid-row: 1/2;
  text-align: right;
  color:#333;
}
.SharePanel_SharePanel__1hqTY a {
  display: inline-block;
  grid-column: span 1;
  grid-row: 2/3;
  text-align: center;
}

.SharePanel_SharePanel__1hqTY a:first-of-type {
  grid-column: 1/4;
  grid-row: 3/4;
}
.SharePanel_SharePanel__1hqTY a img {
  max-height: 5rem;
  height: 40px;
  width: 40px;
}
.SharePanel_Feedback__16aMH {
  grid-column: 1/4;
  grid-row: 1/2;
  text-align: left;
  color:#333;
}
.SharePanel_CloseButton__30z2O svg {
  width: 30px;
}
.SharePanel_CloseButton__30z2O svg path {
  fill: #454a10;
}

.SharePanel_ShareButton__331M0{
  display: inline-block;
  padding: 10px 10px;
  margin-bottom: 0px;
  box-sizing: border-box;
  font-size: 0.9rem;
  width: 33%;
  margin-right: auto;
  margin-left: auto;
  border: none;
  border-radius: 2rem;
  font-family: inherit;
  background-color: #454a10;
  color: #f7ffd8;
  text-transform: capitalize;
  font-weight: 600;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  align-self: center;
}
.ModalPopup_ModalContainer__25c9F {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 1fr 1fr;
  justify-items: center;
  align-content: center;
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 999999;
}

.MenuToggle_MenuToggle__4nKHZ {
  position: relative;
  display: block;
  width: 27px;
  height: auto;
  align-self: center;
  padding: 0 !important;
  transform:scale(1.5)!important;
  margin-left:10px!important;
  margin-TOP:10px!important;
}

.Header_Header__3Kgo5 {
  display: grid;
  grid-template-columns: 20px 48px auto 20px;
  padding-top: calc(5px + 0);
  padding-top: calc(5px + var(--header-x-pad, 0));
  box-sizing: border-box;
  z-index:10001;
}
.Header_LogoLink__1qIha {
  position: absolute;
  display: block;
  width: 64px;
  height: 64px;
  top: 10px;
  right: 10px;
  justify-self: flex-end;
  grid-row: 1/2;
  grid-column: 3/5;
  margin: 0;
  margin-top: 0px;
  text-align: right;
  justify-self: right;
  color: #f7ffd8;
}
.Header_MenuToggle__3Z5zj {
  grid-row: 1/2;
  grid-column: 2/3;
}
.Header_Logo__2oHWU {
  position: relative;
  display: block;
  width: 65px;
  margin-top:-.92rem;
}
.Header_AppIco__1pr8J {
  position: relative;
  height:64px;
  display: block;
  width: 100%;
  background-size:contain;
}
.Header_AvatarIco__2qcWx {
  position: relative;
  height:64px;
  display: block;
  width: 100%;
  background-color:#c8d72c;
  background-size:contain;
  mask-mode:alpha;
  -webkit-mask-image: url(/static/media/avatar-mask-1024-black.848d2a56.png);
          mask-image: url(/static/media/avatar-mask-1024-black.848d2a56.png);
  -webkit-mask-size: 64px;
          mask-size: 64px;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-position: center;
          mask-position: center;
}
.Header_LogoLink__1qIha {
}
.Header_LogoLink__1qIha a {
  margin-top: 0;
}



.Header_LogoLinkSignup__3S9_3 {
  position: absolute;
  display: block;
  width: 126px;
  height: 40px;
  top: 24px;
  right: 10px;
  justify-self: flex-end;
  grid-row: 1/2;
  grid-column: 3/5;
  margin: 0;
  margin-top: 0px;
  text-align: right;
  justify-self: right;
  color: #f7ffd8;
  background:#454a10;
  border-radius:6rem;
}
.Header_LogoLinkSignup__3S9_3 a {
  margin-top: 0;
}
.Header_LogoLinkSignup__3S9_3 a span{
  float:left;
  width:3.14rem;
  padding:1.4rem .16rem .20rem .8rem;
  font-size:1rem;
}

.LoginPage_LoginPage__1_QQK {
  position: relative;
  display: block;
  height: 100%;
  grid-template-rows: 1fr 2fr;
}

.LoginPage_LoginPage__1_QQK section {
  /* JM moved to ../App/ContentSection.module.css */
  /* still possible to add page specific styling 
  * by adding a class here and assigninig it to the ContentSection className
  */
}

.LoginPage_LoginForm__3Fo9S {
  position: relative;
  display: block;
  align-self: flex-start;
}
.LoginPage_SignupLink__2v4iE {
}

.LoginPage_LoginForm__3Fo9S input {
  display: block;
  width: 100%;
  margin: auto;
  padding: 15px 10px;
  margin-bottom: 30px;
  box-sizing: border-box;
  font-size: 1rem;
  font-family: inherit;
  border: none;
  border-radius: 5px;
  background-color: #f7ffd8;
}
.LoginPage_EmailInput__1z_W7 {
}
.LoginPage_PasswordInput__3YjeT {
}

.LoginPage_LoginPage__1_QQK button {
  display: inline-block;
  padding: 10px 10px;
  margin-bottom: 20px;
  box-sizing: border-box;
  font-size: 0.9rem;
  font-weight: 600;
  width: 49%;
  margin-right: 2%;
  border: none;
  border-radius: 2rem;
  font-family: inherit;
  background-color: #454a10;;
  color: #f7ffd8;
}
.LoginPage_LoginPage__1_QQK a {
  display: block;
  text-decoration: none;
  color: #f7ffd8;
  margin-top: 0px;
  font-size: 0.9rem;
}
.LoginPage_LoginPage__1_QQK a.LoginPage_SignupLink__2v4iE {
  display: inline-block;
  padding: 10px 10px;
  margin-top: 0;
  margin-bottom: 20px;
  box-sizing: border-box;
  font-size: 0.9rem;
  font-weight: 600;
  width: 49%;
  text-align: center;
  border: none;
  border-radius: 3px;
  text-decoration: none;
  color: #f7ffd8;
}

.PageHeader_PageHeader__3pcNU {
  position: relative;
  display: block;
  margin: auto;
  margin-bottom:20px;
  margin-top:25px;
  width: 100%;
  color: #f7ffd8;
}

.ContentSection_ContentSection__pByU6 {
  position: relative;
  display: block;
  box-sizing: border-box;
  margin-left: -25px;
  margin-right: -20px;
  padding: 25px;
  /* background-color: rgba(255, 255, 255, 0.5); */
  margin-bottom: 20px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

a.Avatar_link_gravatar__1QRlS{
	display:block;
	width:100%;
	text-align: center;

}
.Avatar_AvatarImage__2X-mq {
	display:block;
  border-radius: 50%;
  max-width: 100%;
  max-height: 100%;
  border: 2px solid #f7ffd8;
  box-sizing: border-box;
  background-color:#f7ffd8;
}
.Avatar_AvatarMaskedImage__QgpcB{
  width:100%;
  height:auto;
  max-width: 256px;
  background-size: contain;
  mask-mode: alpha;
  -webkit-mask-image: url(/static/media/avatar-mask-1024-black.848d2a56.png);
          mask-image: url(/static/media/avatar-mask-1024-black.848d2a56.png);
  -webkit-mask-size: 100%;
          mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-position: center;
          mask-position: center;
}
.ManifestoPage_ManifestoPage__3SE2l {
  margin-left: -20px;
  margin-right: -20px;
}
.ManifestoPage_PageHeader__1lVCv {
  padding-left: 20px;
}
.ManifestoPage_Avatar__1mwJi {
  max-width: 40px;
  display: inline-block;
  margin-right: 10px;
}
.ManifestoPage_ShareButton__2-ubR {
  width: 30px;
}
.ManifestoPage_ShareButton__2-ubR svg {
  position: absolute;
  display: block;
  width: 30px;
  height: auto;
  top: 25px;
  right: 15px;
  opacity: 0.8;
}
.ManifestoPage_ManifestoPage__3SE2l ul {
  position: relative;
  display: block;
  list-style: none;
  padding: 0;
}

.ManifestoPage_ManifestoItem__m2Swz {
  position: relative;
  display: grid;
  width: calc(100% + 12px);
  padding: 20px 33px;
  padding-right: 5px;
  padding-top: 10px;
  margin-left:-10px;
  background-color: rgba(121, 127, 43, 0.5);
  box-sizing: border-box;
  margin-bottom: 5px;
  grid-template-columns: 1fr 40px;
  grid-column-gap: 20px;
}

.ManifestoPage_StatementText__pbnpg {
  position: relative;
  display: block;
  grid-column: 1/2;
  grid-row: 1/2;
  align-self: center;
  font-size: 0.95rem;
  font-weight: 400;
  color: #f7ffd8;
  text-decoration: none;
  margin-top: 0;
  margin-bottom: 10px;
}

.ManifestoPage_StatementText__pbnpg::before {
  content: open-quote;
  position: relative;
  display: block;
  font-weight: 900;
  font-size: 2rem;
  margin-bottom: -5px;
  opacity: 0.5;
}
.ManifestoPage_StatementText__pbnpg::after {
  content: close-quote;
  display: block;
  font-size: 0;
}
.ManifestoPage_ManifestoItem__m2Swz button {
  position: relative;
  display: none;
  width: 100%;
  grid-column: 2/3;
  grid-row: span 1;
  margin: 0;
  align-self: center;
}
.ManifestoPage_ManifestoItem__m2Swz span.ManifestoPage_ManifestoDrag__1MxDl {
  position: relative;
  display: block;
  width: 32px;
  height: 40px;
  overflow: hidden;
  grid-column: 2/3;
  grid-row: 1/2;
  margin: 0;
  opacity: 0.66;
  align-self: flex-start;
}
.ManifestoPage_ManifestoItem__m2Swz span svg {
  fill: #f7ffd8;
  height: 100%;
}
.ManifestoPage_PublicCountSeparator__1Rqvq {
  position: relative;
  display: grid;
  font-size: 0.8rem;
  grid-template-columns: 9fr 1fr;
  grid-template-rows: 1fr auto 1fr;
  margin-left: 15px;
  margin-bottom: 5px;
  opacity: 0.8;
}
.ManifestoPage_PublicCountSeparator__1Rqvq span {
  position: relative;
  display: block;
  grid-column: 2/3;
  grid-row: 1/4;
  align-self: center;
  justify-self: right;
  right: 0px;
}
.ManifestoPage_PublicCountSeparator__1Rqvq span svg {
  fill: #f7ffd8;
  height: 40px;
}
.ManifestoPage_PublicCountSeparator__1Rqvq hr {
  position: relative;
  display: block;
  grid-column: 1/2;
  margin: 0;
  grid-row: 2/3;
}
.ManifestoPage_PublicCountSeparator__1Rqvq label {
  position: relative;
  display: block;
  grid-column: 1/2;
  grid-row: span 1;
  align-self: flex-start;
  font-size: 0.8rem;
  color: #f7ffd8;
  text-decoration: none;
  margin-top: 5px;
  padding-left: 5px;
}

.ManifestoPage_PublicCountSeparator__1Rqvq label:first-of-type {
  align-self: flex-end;
  margin-bottom: 5px;
}

.ManifestoPage_PublicCountSeparator__1Rqvq button {
  position: relative;
  display: none;
  width: 100%;
  grid-column: 2/3;
  grid-row: span 1;
  margin: 0;
  align-self: center;
  right: 4px;
}

.StatementRow_Buttons__32tle {
  position: absolute;
  top: 12px;
  left: 60px;
  height: 30px;
  display: flex;
  z-index: 30;
}
.StatementRow_CommentsButton__1BdOk,
.StatementRow_ShareButton__duxl_ {
  margin-right: 1rem;
}
.StatementRow_CommentsButton__1BdOk span {
  font-size: 0.7rem;
}

.StatementRow_CommentsButton__1BdOk svg,
.StatementRow_ShareButton__duxl_ svg {
  height: 28px;
   fill: #f7ffd8;
   
}
.StatementRow_RemoveButton__3eKwN {
  height: 24px;
  width:24px;
  margin-left:0.5rem;
}
.StatementRow_RemoveButton__3eKwN path {
  /* fill:rgba(136,143,46,0.5); */
  fill: #f7ffd8;
}

.ProfilePage_ProfilePage__12swl section {
  /* moved 
	background-color: rgba(255,255,255,0.5);
	padding: 25px;
	margin-left: -25px;
	margin-right: -25px;
	margin-top: 20px;
	*/
}

.ProfilePage_EditButton__1DqDx{
  position: relative;
  display: block;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
  margin: auto;
  margin-left: 0;
  padding: 10px 20px;
  color: #f7ffd8;
  border-radius: 2rem;
  background: #454a10;
}

.CreatePage_CreatePage__NZtPs {
  position: relative;
  display: grid;
  height: 100%;
  grid-template-rows: .25fr 2fr;
}
.CreatePage_LoginForm__2AsOd input {
  display: block;
  width: 100%;
  margin: auto;
  padding: 15px 10px;
  margin-bottom: 20px;
  box-sizing: border-box;
  font-size: 1rem;
  font-family: inherit;
  border: thin #999 solid;
  border-radius: 3px;
  background-color: #f7ffd8;
}
.CreatePage_EmailInput__3hXs_ {
}
.CreatePage_PasswordInput__1HqUM {
}

.CreatePage_CreatePage__NZtPs button {
  display: inline-block;
  padding: 10px 10px;
  margin-bottom: 0px;
  box-sizing: border-box;
  font-size: 0.9rem;
  /* width: 33%; */
  margin-right: 2%;
  border: none;
  border-radius: 2rem;
  font-family: inherit;
  background-color: #454a10;
  color: #f7ffd8;
  text-transform: capitalize;
  font-weight: 600;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  align-self: center;
}
.CreatePage_LoginLink__3wXCz {   
  display:inline-block; 
  width: 45%;
  text-align: center;
}
.Menu_Menu__2oTx4 {
  transform: translateX(-100%);
  transition: transform 0.2s ease-in-out;
  background-color: #9baa0f;
}
.Menu_Menu__2oTx4.Menu_Show__2Had3 {
  transform: translateX(0);
}
.Menu_Backdrop__3ieU1{
  opacity: 1;
}
.Menu_Menu__2oTx4 ul {
  position: relative;
  height:100%;
  /* display: grid;
  grid-template-columns: 20px auto 20px; */
  list-style: none;
  /* height: auto; */
  margin: 0;
  padding: 0;
  padding-top: 10%;
  padding-bottom: 15%;
  box-sizing: border-box;
}

.Menu_Menu__2oTx4 ul li {
  /* position: relative;
  display: flex;
  grid-column: 2/3;
  grid-row: span 1;
  align-content: center; */
}
.Menu_Menu__2oTx4 ul li.Menu_AccountMenuItem__3LDBj {
  display: grid;
  grid-template-columns: auto 1fr;
}

li.Menu_AccountMenuItem__3LDBj a img {
  margin-right: 20px;
}
.Menu_Menu__2oTx4 ul li a {
  position: absolute;
  display: block;
  text-decoration: none;
  align-self: center;
  color: #eee;
  font-size: 1.5rem;
  text-transform: lowercase;
  font-feature-settings: "smcp", "c2sc";
  font-variant: all-small-caps;
  letter-spacing: 2px;
  padding-top: 0px;
  padding-bottom: 10px;
}
.Menu_Menu__2oTx4 ul li a img{
  width:100%;
  height:auto;
}

.Menu_AccountMenuItem__3LDBj a {
  height: 30px;
  position: relative;
  display: block;
}
.Menu_AccountMenuItem__3LDBj a img {
  /* display: inline-block; */
}

.Menu_ButtonMask__3Flf-{
  background-size:90%;
  mask-mode:alpha;
  -webkit-mask-image: url(/static/media/caim-menu-background.ac53a2e2.png);
          mask-image: url(/static/media/caim-menu-background.ac53a2e2.png);
  -webkit-mask-size: 150%;
          mask-size: 150%;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-position: 50% 30%;
          mask-position: 50% 30%;
  background: rgba(255, 255, 255, 0.6);
  padding:10%;
}
.Menu_AboutButton__3pyXs{
  -webkit-mask-size: 230%;
          mask-size: 230%;
  -webkit-mask-position: -3% 13%;
          mask-position: -3% 13%;
}
.Menu_CommonManifestoButton__2JIoy{
  -webkit-mask-size: 137%;
          mask-size: 137%;
  -webkit-mask-position: 114% 11%;
          mask-position: 114% 11%;
}
.Menu_BrowseStatementsButton__2_n6R{
  -webkit-mask-position: -6% 41%;
          mask-position: -6% 41%;
}
.Menu_AccountButton__2YN4f{
  -webkit-mask-size: 232%;
          mask-size: 232%;
  -webkit-mask-position: 99% 40%;
          mask-position: 99% 40%;
}
.Menu_ContributeStatementButton__23Hh7{
  -webkit-mask-size: 150%;
          mask-size: 150%;
  -webkit-mask-position: 109% 60%;
          mask-position: 109% 60%;
}
.Menu_MyManifestoButton__wALfS{
  -webkit-mask-position: -6% 79%;
          mask-position: -6% 79%;
}
.Menu_ModerateContentButton__2GovR{
  -webkit-mask-position: 112% 97%;
          mask-position: 112% 97%;
}
/* 
.ButtonMask{
  animation: glitch 1s linear infinite;
}

@keyframes glitch{
  2%,64%{
    transform: translate(2px,0) skew(0deg);
  }
  4%,60%{
    transform: translate(-2px,0) skew(0deg);
  }
  62%{
    transform: translate(0,0) skew(5deg); 
  }
}

.ButtonMask:before,
.ButtonMask:after{
  content: attr(title);
  position: absolute;
  left: 0;
}

.ButtonMask:before{
  animation: glitchTop 1s linear infinite;
  clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
}

@keyframes glitchTop{
  2%,64%{
    transform: translate(2px,-2px);
  }
  4%,60%{
    transform: translate(-2px,2px);
  }
  62%{
    transform: translate(13px,-1px) skew(-13deg); 
  }
}

.ButtonMask:after{
  animation: glitchBotom 1.5s linear infinite;
  clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
  -webkit-clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
}

@keyframes glitchBotom{
  2%,64%{
    transform: translate(-2px,0);
  }
  4%,60%{
    transform: translate(-2px,0);
  }
  62%{
    transform: translate(-22px,5px) skew(21deg); 
  }
} */
.PixelOverlay_PixelOverlay__3sRjk {
  pointer-events: none;
}

.PixelOverlay_PixelOverlay__3sRjk svg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.PixelOverlay_PixelOverlay__3sRjk rect {
  transition: opacity 0.3s ease-in-out;
}

.LogoutPage_LogoutPage__1GvPk {
  position: relative;
  display: grid;
  height: 100%;
  grid-template-rows: 1fr 2fr;
}
.LogoutPage_LogoutPage__1GvPk h2{
	display: none;
}
.LogoutPage_LogoutPage__1GvPk button {
  display: inline-block;
  padding: 10px 10px;
  margin-bottom: 00px;
  box-sizing: border-box;
  font-size: 0.9rem;
  /* width: 33%; */
  margin-right: 2%;
  border: none;
  border-radius: 2rem;
  font-family: inherit;
  background-color: #454a10;;
  color:#f7ffd8;;
  text-transform: capitalize;
  font-weight: 600;
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
	align-self: center;
}

.Button_Button__2Wr8f{
  display: inline-block;
  padding: 10px 10px;
  margin-bottom: 0px;
  box-sizing: border-box;
  font-size: 0.9rem;
  /* width: 33%; */
  margin-right: 2%;
  border: none;
  border-radius: 2rem;
  font-family: inherit;
  background-color: #454a10;
  color: #f7ffd8;
  text-transform: capitalize;
  font-weight: 600;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  align-self: center;
  margin:auto;
  margin-bottom:18px;
}
.PopUp_PopUp__1SDQR{
  position:absolute;
  width: 100%;
  height:100%;
  z-index:12000;
}

.PopUp_PopUpBackground__2nHPS{
  background:rgba(154, 164, 43, .6)  url(/static/media/popup_background-01.df38709b.svg) no-repeat;
  /* background:; */
  width: 100%;
  height:100%;
  position:absolute; 
  top:0;
  left:0;
}

.PopUp_PopUpContent__2QshX{
  width: calc(100% - 180px);
  margin:70px;
  height:auto;
  padding:20px;
  position:absolute; 
  top:0;
  left:0;
  min-height:50%;
}
.MyProfilePage_MyProfilePage__1sHMO {
}

.MyProfilePage_MyProfilePage__1sHMO section {
  /* JM moved to ../App/ContentSection.module.css */
}
.MyProfilePage_gravatar_caption__2tvCy {
  position: relative;
  display: none;
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
}

.MyProfilePage_gravatar_caption__2tvCy a {
  color: #333;
}

.MyProfilePage_profile_edit_field__oMySw {
  position: relative;
  display: block;
}

.MyProfilePage_profile_edit_field__oMySw label {
  position: relative;
  display: block;
  margin-bottom: 10px;
  font-size: 0.8rem;
}

.MyProfilePage_profile_edit_field__oMySw input {
  display: block;
  width: 100%;
  margin: auto;
  padding: 15px 10px;
  margin-bottom: 20px;
  box-sizing: border-box;
  font-size: 1rem;
  font-family: inherit;
  border: none;
  border-radius: 3px;
}

.MyProfilePage_MyProfilePage__1sHMO button {
  margin-top: 10px;
  text-transform: capitalize;
  font-size: 0.9rem;
  font-weight: 600;
  border: none;
  /* -webkit-border-radius: 1em;
  -moz-border-radius: 1em; */
  border-radius: 2em;
  font-family: inherit;
  background-color: #454a10;
  color: #f7ffd8;
  width: 33%;
}


.MyProfilePage_AvatarPickerButton__2SoDx{
  display: inline-block;
  padding: 10px 10px;
  margin-bottom: 0px;
  box-sizing: border-box;
  font-size: 0.9rem;
  /* width: 33%; */
  margin-right: 2%;
  border: none;
  border-radius: 2rem;
  font-family: inherit;
  background-color: #454a10;
  color: #f7ffd8;
  text-transform: capitalize;
  font-weight: 600;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  align-self: center;
  margin:auto;
  margin-bottom:18px;
}

.MyProfilePage_ButtonBar__2DjBn{

  display:grid;
  grid-template-columns: 48% 4% 48%;
}
.MyProfilePage_ButtonBar__2DjBn > *{
  width:100% !important;
  text-align:center;
}
.AddStatementPage_AddStatementPage__BUQa7 {
  position: relative;
  display: grid;
  height: 100%;
  grid-template-rows: -webkit-max-content -webkit-max-content auto;
  grid-template-rows: max-content max-content auto;
}
.AddStatementPage_AddStatementPage__BUQa7 section {
  /* moved
   position: relative;
	display: block;
		margin-left: -20px;
	margin-right: -20px;
	padding:15px 25px;
	background-color: rgba(255,255,255,0.5);
	-webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px; */
}
.AddStatementPage_AddStatementPage__BUQa7 textarea {
  position: relative;
  display: block;
  width: 100%;
  height: 120px;
  margin: auto;
  padding: 10px 10px;
  margin-bottom: 20px;
  box-sizing: border-box;
  font-size: 1rem;
  font-family: inherit;
  border: none;
  border-radius: 3px;
}

.AddStatementPage_AddStatementPage__BUQa7 label {
  position: relative;
  display: block;
  font-size: 0.8rem;
  margin-bottom: 10px;
  color: #555;
}

.AddStatementPage_AddStatementPage__BUQa7 label input {
  position: relative;
  display: block;
  width: 100%;
  margin-right: 25px;
  box-sizing: border-box;
  padding: 10px 10px;
  font-size: 1rem;
  margin-top: 5px;
  border-radius: 3px;
  border: none;
}
.AddStatementPage_AddStatementPage__BUQa7 button {
  display: inline-block;
  padding: 10px 25px;
  margin-bottom: 20px;
  margin-top: 10px;
  box-sizing: border-box;
  font-size: 0.9rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-right: 2%;
  border: none;
  border-radius: 2rem;
  font-family: inherit;
  background-color: #454a10;
  color: #f7ffd8;
  text-transform: capitalize;
  font-weight: 600;
}


.AddStatementPage_AddStatementPage__BUQa7 > section > p:nth-child(3){
 font-weight:600;
}
.Breadcrumb_Breadcrumb__1H8Jj{
  color:#f7ffd8;
}

.Breadcrumb_Breadcrumb__1H8Jj a{
  display:inline-block;
  margin:0 4px 0 0;
}

.Breadcrumb_Breadcrumb__1H8Jj a::before{
  content:" > "
}

.Breadcrumb_Breadcrumb__1H8Jj a:last-child::after{
  content:" >"
}
.StatementRow_Buttons__2f4vx {
  position: absolute;
  top: 12px;
  left: 60px;
  height: 30px;
  display: flex;
  z-index: 30;
}
.StatementRow_CommentsButton__2UDeg,
.StatementRow_ShareButton__1JwN2 {
  margin-right: 1rem;
}
.StatementRow_CommentsButton__2UDeg span {
  font-size: 0.7rem;
}

.StatementRow_CommentsButton__2UDeg svg,
.StatementRow_ShareButton__1JwN2 svg {
  height: 28px;
   fill: #f7ffd8;
   
}
.StatementRow_RemoveButton__2JLFL {
  height: 24px;
  width:24px;
  margin-left:0.5rem;
}
.StatementRow_RemoveButton__2JLFL path {
  /* fill:rgba(136,143,46,0.5); */
}


.StatementRow_StatementRow__2EJnN{
  padding-left:20px;
  margin-left:-20px;
  margin-right:-20px;
  padding-bottom:12px;
  padding-right:32px;
  margin-bottom:6px;
}
.StatementRow_StatementRow__2EJnN {
  position: relative;
  display: grid;
  width: 100%;
  padding: 20px 25px;
  padding-right: 5px;
  padding-top: 10px;
  box-sizing: border-box;
  margin-bottom: 5px;
  grid-template-columns: 1fr 40px;
  grid-column-gap: 20px;
}

.StatementRow_StatementText__1ivH6 {
  position: relative;
  display: block;
  grid-column: 1/2;
  grid-row: 1/2;
  align-self: center;
  margin-top: 0;
  margin-bottom: 10px;
  /* margin-left: 10px; */
}


.StatementRow_StatementText__1ivH6 > span{
  display:inline;
  background:rgba(121, 127, 43, 0.5);
  box-shadow: 15px 0 0 rgb(121 127 43 / 50%), -80px 0 0 rgb(121 127 43 / 50%);
  font-size: 1.2rem;
  font-weight: 800;
  color: #f7ffd8;
  text-decoration: none;
}
.StatementRow_StatementText__1ivH6::before {
  content: open-quote;
  position: relative;
  display: block;
  font-weight: 900;
  font-size: 2rem;
  margin-bottom: -5px;
  color:#f7ffd8;
}
.StatementRow_StatementText__1ivH6::after {
  content: close-quote;
  display: block;
  font-size: 0;
}
.StatementRow_StatementRow__2EJnN button {
  position: relative;
  display: none;
  width: 100%;
  grid-column: 2/3;
  grid-row: span 1;
  margin: 0;
  align-self: center;
}
.StatementRow_StatementRow__2EJnN span.StatementRow_ManifestoDrag__3bbDk {
  position: relative;
  display: block;
  width: 32px;
  height: 40px;
  overflow: hidden;
  grid-column: 2/3;
  grid-row: 1/2;
  margin: 0;
  opacity: 0.66;
  align-self: flex-start;
}
.StatementRow_StatementRow__2EJnN span svg {
  fill: #f7ffd8;
  height: 100%;
}

.StatementRow_StatementText__1ivH6 > span.StatementRow_StatementDeleted__2u0AG {
  text-decoration: line-through;
}
.ModeratePopupsPage_AddPopupPage__3Xk0s {
  position: relative;
  display: grid;
  height: 100%;
  grid-template-rows: -webkit-max-content -webkit-max-content auto;
  grid-template-rows: max-content max-content auto;
}
.ModeratePopupsPage_AddPopupPage__3Xk0s section {
  /* moved
   position: relative;
	display: block;
		margin-left: -20px;
	margin-right: -20px;
	padding:15px 25px;
	background-color: rgba(255,255,255,0.5);
	-webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px; */
}
.ModeratePopupsPage_AddPopupPage__3Xk0s textarea {
  position: relative;
  display: block;
  width: 100%;
  height: 120px;
  margin: auto;
  padding: 10px 10px;
  margin-bottom: 20px;
  box-sizing: border-box;
  font-size: 1rem;
  font-family: inherit;
  border: none;
  border-radius: 3px;
}

.ModeratePopupsPage_AddPopupPage__3Xk0s label {
  position: relative;
  display: block;
  font-size: 0.8rem;
  margin-bottom: 10px;
  color: #555;
}

.ModeratePopupsPage_AddPopupPage__3Xk0s label input {
  position: relative;
  display: block;
  width: 100%;
  margin-right: 25px;
  box-sizing: border-box;
  padding: 10px 10px;
  font-size: 1rem;
  margin-top: 5px;
  border-radius: 3px;
  border: none;
}
.ModeratePopupsPage_AddPopupPage__3Xk0s button {
  display: inline-block;
  padding: 10px 25px;
  margin-bottom: 20px;
  margin-top: 10px;
  box-sizing: border-box;
  font-size: 0.9rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-right: 2%;
  border: none;
  border-radius: 2rem;
  font-family: inherit;
  background-color: #454a10;
  color: #f7ffd8;
  text-transform: capitalize;
  font-weight: 600;
}


.ModeratePopupsPage_AddPopupPage__3Xk0s > section > p:nth-child(3){
 font-weight:600;
}
.UserRow_Buttons__2DVxy {
  position: absolute;
  top: 12px;
  left: 60px;
  height: 30px;
  display: flex;
  z-index: 30;
}
.UserRow_CommentsButton__2MMzI,
.UserRow_ShareButton__2V5LU {
  margin-right: 1rem;
}
.UserRow_CommentsButton__2MMzI span {
  font-size: 0.7rem;
}

.UserRow_CommentsButton__2MMzI svg,
.UserRow_ShareButton__2V5LU svg {
  height: 28px;
   fill: #f7ffd8;
   
}
.UserRow_RemoveButton__3o0bj {
  height: 24px;
  width:24px;
  margin-left:0.5rem;
}
.UserRow_RemoveButton__3o0bj path {
  /* fill:rgba(136,143,46,0.5); */
}


.UserRow_UserRow__w2Mba{
  width:100%;
  display: grid;
  grid-template-columns:  50px  calc(100% - 150px) 50px 50px;
  grid-row: 1/2;
  align-self: center;
  margin-top: 0;
  margin-bottom: 10px;
  position: relative;
  width: 100%;
  /* padding: 0 25px;
  padding-right: 5px;
  padding-top: 10px; */
  box-sizing: border-box;
  margin-bottom: 5px;
  grid-column-gap: 20px;
  padding-left:20px;
  margin-left:-20px;
  margin-right:-20px;
  padding-bottom:12px;
  padding-right:32px;
  margin-bottom:6px;
}

.UserRow_UserText__1hO2d {
  /* margin-left: 10px; */
}


.UserRow_UserText__1hO2d > span{
  display:inline-block;
  font-size: 1.1rem;
  font-weight: 800;
  color: #f7ffd8;
  text-decoration: none;
}
.UserRow_UserRow__w2Mba button {
  position: relative;
  display: none;
  width: 100%;
  grid-column: 2/3;
  grid-row: span 1;
  margin: 0;
  align-self: center;
}
.UserRow_UserRow__w2Mba span.UserRow_ManifestoDrag__99qnR {
  position: relative;
  display: block;
  width: 32px;
  height: 40px;
  overflow: hidden;
  grid-column: 2/3;
  grid-row: 1/2;
  margin: 0;
  opacity: 0.66;
  align-self: flex-start;
}
.UserRow_UserRow__w2Mba span svg {
  fill: #f7ffd8;
  height: 100%;
}

.UserRow_UserText__1hO2d > span.UserRow_UserDeleted__1Q8Wc {
  text-decoration: line-through;
  opacity:.6;
}

.UserRow_Avatar__2KhYW{
  margin-top:0px;
  width:54px !important;
  height:auto;
}

/* .UserRow{
  display:flex;
} */
span svg{
width: 100%;
height: Auto;
}
.AverageManifestoPage_ManifestoPage__HZ6kR {
  margin-left: -20px;
  margin-right: -20px;
}
.AverageManifestoPage_PageHeader__3C0Jq {
  /* padding-left: 10px; */
}
.AverageManifestoPage_Avatar__wDr6p {
  max-width: 40px;
  display: inline-block;
  margin-right: 10px;
}
.AverageManifestoPage_ShareButton__3ksmQ {
  width: 30px;
}
.AverageManifestoPage_ShareButton__3ksmQ svg {
  position: absolute;
  display: block;
  width: 30px;
  height: auto;
  top: 25px;
  right: 15px;
  opacity: 0.8;
}
.AverageManifestoPage_ManifestoPage__HZ6kR ul {
  position: relative;
  display: block;
  list-style: none;
  padding: 0;
}

.AverageManifestoPage_AverageManifestoItem__2dMz_{
  padding-left:20px;
  margin-left:-20px;
  margin-right:-20px;
  padding-bottom:12px;
  padding-right:32px;
  margin-bottom:6px;
}
.AverageManifestoPage_ManifestoItem__3sRDo {
  position: relative;
  display: grid;
  width: 100%;
  padding: 20px 25px;
  padding-right: 5px;
  padding-top: 10px;
  box-sizing: border-box;
  margin-bottom: 5px;
  grid-template-columns: 1fr 40px;
  grid-column-gap: 20px;
}

.AverageManifestoPage_StatementText__1zdoJ {
  position: relative;
  display: block;
  grid-column: 1/2;
  grid-row: 1/2;
  align-self: center;
  margin-top: 0;
  margin-bottom: 10px;
  /* margin-left: 10px; */
}

/*
.StatementText > span{
  display:inline;
  background:rgba(121, 127, 43, 0.5);
  box-shadow: 15px 0 0 rgb(121 127 43 / 50%), -20px 0 0 rgb(121 127 43 / 50%);
  font-size: 1.2rem;
  font-weight: 800;
  color: #f7ffd8;
  text-decoration: none;
}
.StatementText::before {
  content: open-quote;
  position: relative;
  display: block;
  font-weight: 900;
  font-size: 2rem;
  margin-bottom: -5px;
  color:#f7ffd8;
}
.StatementText::after {
  content: close-quote;
  display: block;
  font-size: 0;
}*/
.AverageManifestoPage_ManifestoItem__3sRDo button {
  position: relative;
  display: none;
  width: 100%;
  grid-column: 2/3;
  grid-row: span 1;
  margin: 0;
  align-self: center;
}
.AverageManifestoPage_ManifestoItem__3sRDo span.AverageManifestoPage_ManifestoDrag__3cfAb {
  position: relative;
  display: block;
  width: 32px;
  height: 40px;
  overflow: hidden;
  grid-column: 2/3;
  grid-row: 1/2;
  margin: 0;
  opacity: 0.66;
  align-self: flex-start;
}
.AverageManifestoPage_ManifestoItem__3sRDo span svg {
  fill: #f7ffd8;
  height: 100%;
}
.AverageManifestoPage_PublicCountSeparator__J_ucb {
  position: relative;
  display: grid;
  font-size: 0.8rem;
  grid-template-columns: 9fr 1fr;
  grid-template-rows: 1fr auto 1fr;
  margin-left: 15px;
  margin-bottom: 5px;
  opacity: 0.8;
}
.AverageManifestoPage_PublicCountSeparator__J_ucb span {
  position: relative;
  display: block;
  grid-column: 2/3;
  grid-row: 1/4;
  align-self: center;
  justify-self: right;
  right: 0px;
}
.AverageManifestoPage_PublicCountSeparator__J_ucb span svg {
  fill: #f7ffd8;
  height: 40px;
}
.AverageManifestoPage_PublicCountSeparator__J_ucb hr {
  position: relative;
  display: block;
  grid-column: 1/2;
  margin: 0;
  grid-row: 2/3;
}
.AverageManifestoPage_PublicCountSeparator__J_ucb label {
  position: relative;
  display: block;
  grid-column: 1/2;
  grid-row: span 1;
  align-self: flex-start;
  font-size: 0.8rem;
  color: #f7ffd8;
  text-decoration: none;
  margin-top: 5px;
  padding-left: 5px;
}

.AverageManifestoPage_PublicCountSeparator__J_ucb label:first-of-type {
  align-self: flex-end;
  margin-bottom: 5px;
}

.AverageManifestoPage_PublicCountSeparator__J_ucb button {
  position: relative;
  display: none;
  width: 100%;
  grid-column: 2/3;
  grid-row: span 1;
  margin: 0;
  align-self: center;
  right: 4px;
}

.ModerationPage_PageHeader__1H9lZ{
  padding-left:0;
}

/* p{
  color: #f7ffd8 !important;
} */

ul{
  list-style: none;
  margin:0;
  padding:0;
  font-size:1.5em;
  font-weight:900;
}

.ActionBar_ActionBar__327PR {
  /* grid-column: 1/4; */
  display: grid;
  grid-template-columns: 2fr 1fr 4fr 1fr 2fr;
  justify-items: center;
  text-align: center;
  /* align-content: center; */
  /* align-items: flex-start; */
  margin-top: 0px;
  margin-bottom: 0px;
  padding-bottom: 20px;
  padding-top: 20px;
  box-sizing: border-box;
  /* max-width: 480px; */
  text-align: center;
}
.ActionBar_Left__3CpMj,
.ActionBar_Right__Dl49V,
.ActionBar_Main__2EMSA ,
.ActionBar_MainRight__2mUTh ,
.ActionBar_MainLeft__24FJU {
  position: relative;
  display: flex;
  flex-direction: column;
}
.ActionBar_Left__3CpMj {
  /* align-items: flex-start; */
}
.ActionBar_Right__Dl49V {
  /* align-items: flex-end;
  align-self: end;
  align-items: center; */
}
.ActionBar_MainLeft__24FJU {
  align-items: center;
  margin: 0;
  grid-column: 2;
  grid-row: 1/2;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
}
.ActionBar_Main__2EMSA {
  align-items: center;
  margin: 0;
  grid-column: 3;
  grid-row: 1/2;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
}
.ActionBar_MainRight__2mUTh {
  align-items: center;
  margin: 0;
  grid-column: 4;
  grid-row: 1/2;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
}
.ActionBar_MainLeft__24FJU svg,
.ActionBar_Main__2EMSA svg,
.ActionBar_MainRight__2mUTh svg {
  fill: #f7ffd8;
  height: 60px;
  margin-top: 0px;
}
.ActionBar_MainLeft__24FJU span, 
.ActionBar_Main__2EMSA span, 
.ActionBar_MainRight__2mUTh span {
  display: block;
  margin-top: 0px;
}
.ActionBar_MainRight__2mUTh span a, 
.ActionBar_Main__2EMSA span a, 
.ActionBar_MainLeft__24FJU span a {
  position: relative;
  display: block;
  margin-top: 0;
  height: 65px;
  fill: #dcee3f;
}

.ActionBar_Left__3CpMj {
  margin: auto;
  margin-top: 20px;
  grid-column: 1;
  grid-row: 1/2;
  width: 96px;
  display: block;
}
.ActionBar_Left__3CpMj a {
  fill: #f7ffd8;
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  margin-top: 0;
}
.ActionBar_Left__3CpMj a svg {
  width: 40px;
}
.ActionBar_Left__3CpMj svg path {
  fill: #f7ffd8;
}

.ActionBar_Right__Dl49V {
  margin: auto;
  margin-top: 20px;
  grid-column: 5;
  grid-row: 1/2;
  width: 96px;
  display: block;
}
.ActionBar_Right__Dl49V a {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  margin-top: 0;
}
.ActionBar_Right__Dl49V a svg {
  fill: #f7ffd8;
  width: 40px;
}

.ActionBar_ActionBar__327PR .ActionBar_Main__2EMSA svg {
  /* _opacity: 0.8; */
}
.ActionBar_Main__2EMSA a {
  margin-top: 0 !important;
}
.ActionBar_ActionBar__327PR label {
  font-size: 1rem;
  _background-color: rgba(222, 222, 162, 0.33);
  color: white;
  font-family: inherit;
  bottom: 0;
  margin-top: 15px;
  letter-spacing: 1px;
  font-feature-settings: "smcp";
  font-variant: small-caps;
  text-transform: lowercase;
  font-weight: 700;
  box-sizing: border-box;
  line-height: 1;
  padding: 0;
  padding: 2px 10px;
  border-radius: 4px;
}
.ActionBar_ActionBar__327PR .ActionBar_Left__3CpMj label {
  align-self: flex-end;
  display: none;
}
.ActionBar_ActionBar__327PR .ActionBar_MainLeft__24FJU label {
  align-self: stretch;
  display: block;
  margin-bottom: 20px;
  margin-top: 0px;
}
.ActionBar_ActionBar__327PR .ActionBar_Main__2EMSA label {
  align-self: stretch;
  display: block;
  margin-bottom: 20px;
  margin-top: 0px;
}
.ActionBar_ActionBar__327PR .ActionBar_MainRight__2mUTh label {
  align-self: stretch;
  display: block;
  margin-bottom: 20px;
  margin-top: 0px;
}
.ActionBar_ActionBar__327PR .ActionBar_Right__Dl49V label {
  align-self: flex-start;
  display: none;
}

.RequestResetPage_RequestResetPage__3ozBs {
  position: relative;
  display: grid;
  height: 100%;
  grid-template-rows: 1fr 2fr;
}
.RequestResetPage_RequestResetPage__3ozBs .RequestResetPage_PageHeader__1NSH3 {
  /* has no effect */
  grid-row: 1/2;
  grid-column: 1/2;
}
.RequestResetPage_RequestResetPage__3ozBs p {
  position: relative;
  grid-row: 1/2;
  grid-column: 1/2;
  margin-top: 140px;
}
.RequestResetPage_RequestResetPage__3ozBs form {
  position: relative;
  display: block;
  align-self: flex-start;
}

.RequestResetPage_RequestResetPage__3ozBs form input {
  display: block;
  width: 100%;
  margin: auto;
  padding: 15px 10px;
  margin-bottom: 20px;
  box-sizing: border-box;
  font-size: 1rem;
  font-family: inherit;
  border: thin #999 solid;
  border-radius: 3px;
  background-color: #f7ffd8;
}

.RequestResetPage_RequestResetPage__3ozBs form button {
  display: inline-block;
  padding: 10px 10px;
  margin-bottom: 20px;
  box-sizing: border-box;
  font-size: 1rem;
  width: auto;
  border:0;
  border-radius: 2rem;
  font-family: inherit;
  color:#f7ffd8;
  background:#454a10;
}

.ResetPasswordPage_ResetPasswordPage__3neCI {
}
.ResetPasswordPage_Form__2Nemu {
}

h2.HomePage_caimTitle__2ubSv {
  display: block;
  align-self: flex-end;
  text-align: center;
  color: #f7ffd8;
  bottom: 0;
  margin: auto;
  text-transform: lowercase;
  font-feature-settings: "smcp", "c2sc";
  font-variant: all-small-caps;
  margin-top: 340px;
  font-size: 2rem;
  z-index: 2;
}
h2.HomePage_caimTitle__2ubSv::before {
  _content: " ";
  position: fixed;
  top: 40px;
  left: 20px;
  height: 100%;
  width: 100%;
  background-image: url(/static/media/scanlines.2705ae0a.png);
  z-index: 1;
  opacity: 0.4;
}
h2.HomePage_caimTitle__2ubSv::after {
  content: " ";
  position: fixed;
  top: 50px;
  left: 20px;
  height: 400px;
  width: 100%;
  _z-index: -1;
  background-image: url(/static/media/parrot.f0d83e02.png);
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}
.HomePage_Parrot__1GbgO {
  position: relative;
  max-height: 400px;
  max-width: 100%;
  top: -60px;
  margin-bottom: -100px;
  left: calc(50% + 20px);
  transform: translateX(-50%);
}
.HomePage_HomePage__10Qyw p {
  position: relative;
  display: block;
  font-size: 1.4rem;
  font-weight: 900;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
  color: #f7ffd8;
  margin-bottom: 30px;
  text-align: justify;
}

.HomePage_HomePage__10Qyw p small {
  position: relative;
  display: block;
  font-size: 1.8rem;
  font-weight: 900;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
  color: #f7ffd8;
  margin-top: 10px;
}
.HomePage_HomePage__10Qyw p span {
  display: block;
  text-transform: lowercase;
  font-feature-settings: "smcp", "c2sc";
  font-variant: all-small-caps;
  font-size: 2.4rem;
  text-align: center;
  line-height: 1;
  margin-top: 20px;
}

.HomePage_BrowseButton__1H5Qz {
  position: relative;
  display: block;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
  margin: auto;
  margin-left: 0;
  padding: 10px 20px;
  /* background-color: #f7ffd8; */
  color: #f7ffd8;
  font-size: 1.2rem;
  border-radius: 2rem;
  z-index: +1;
  top: 0px;
  font-weight: 900;
  /* border: 4px #f7ffd8 solid; */
  text-align: right;
  align-self: center;
  justify-self: flex-end;
  margin-left: auto;
  background: #454a10;
}

.HomePage_BrowseButton_blur__3cIaD {
  position: relative;
  display: block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  margin-left: 0;
  padding: 10px 20px;
  background-color: #f7ffd8;
  color: #333;
  font-size: 0.9rem;
  border-radius: 3px;
  -webkit-filter: blur(5px);
          filter: blur(5px);
  opacity: 0.8;
  bottom: 80px;
  left: 50px;
}

.HomePage_LoginButton__2em9Z {
  position: relative;
  display: none;
  font-size: 0.9rem;
  padding: 10px 5px;
  padding-right: 30px;
  margin-top: 50px;
}

.HomePage_SignupButton__1t3MF {
  position: relative;
  display: none;
  font-size: 0.9rem;
  padding-right: 30px;
  margin-top: 50px;
}
.HomePage_colorDummy__3WnrR {
  position: fixed;
  display: none;
  top: 50%;
  left: 0%;
  width: 100%;
  text-align: center;
  z-index: -1;
  color: transparent;
}

.AboutPage_AboutPage__kqZDV {
  position: relative;
  /* display: flex; */
  /* _width: 100%; */
  height: auto;
  margin-top:120px;
  padding: 20px;
  padding-left:38px;
  margin-left: -30px;
  margin-right: -30px;
  background-color: rgba(121,127,43,.5);
  flex-wrap: wrap;
  flex-flow: column;
  box-sizing: border-box;
  align-content: center;
  align-items: center;

  _box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.5);
}
.AboutPage_AboutPage__kqZDV h3{
  color:#F7FFD8;
}

.AboutPage_AboutPageHeader__xQlWb {
  color: #F7FFD8;
  margin-bottom: 20px !important;
  align-self: center;
  margin-top: auto;
}
.AboutPage_AboutPageSubHeader__3xkBZ {
  color: #333#F7FFD8;
}
p {
  margin-bottom: 20px;
  align-self: center;
  color: #F7FFD8;
}
p:last-child {
  margin-bottom: auto;
}

.AvatarPicker_AvatarPicker__2wcE2 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1em;
  gap: 1em;
}

.AvatarPicker_AvatarPicker__2wcE2 span{
  transition: all .2s ease-in-out;
  border-radius:50%;
}

.AvatarPicker_Current__OxQaB {
  background-color: rgba(247, 255, 214, .4);
}

